
import React, { useMemo, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import { useNavigate } from "react-router-dom";
import useResponsiveFonts from "../hooks/useResponsiveFonts"; 


const disqualifyingConditions: string[] = [
  "Pregnant or currently breastfeeding",
  "Active cancer, chemotherapy, or radiation in the last 6 months",
  "Active drug or alcohol abuse",
  "Serious mental health conditions",
  "Kidney disease or transplant",
  "Active hepatitis or liver disease",
  "Daily steroid use (e.g. prednisone)",
  "Heart attack, stroke, or heart conditions that limit daily activity in the last 6 months",
  "Active gallbladder disease",
  "History of bariatric surgery"
];

interface HealthHistoryProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number; 
}

const HealthHistory: React.FC<HealthHistoryProps> = ({ availableHeight, availableWidth }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFieldUpdate = useCallback(
    (value: string | null) => {
      const payload = { field: "healthHistory", value };
      dispatch(setFormValue(payload));

      if (value === "No exsisting disqualifying factors") {
        navigate("/comorbiditiesform");
      } else {
        navigate("/disqualified");
      }
    },
    [dispatch, navigate]
  );

  const selectedHistory = form.healthHistory;
  const isNoneSelected = selectedHistory === null;

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32, // Desktop font size for <h2>
      subheading: 20, // Desktop font size for <p>
      buttonText: 18, // Desktop font size for buttons
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 19, // Mobile font size for <h2>
      subheading: 14, // Mobile font size for <p>
      buttonText: 14, // Mobile font size for buttons
    }),
    []
  );

  // Use the custom hook to get scaled font sizes
  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );

  return (
    <div
      className="flex flex-col items-center xs:mt-6 md:mt-16 xs:mb-[5rem] md:mb-0"
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div className="xs:w-[90%] md:w-full lg:max-w-6xl mx-auto">
        <h2 className="font-bold text-left md:text-center text-[#0B1838]" style={{ fontSize: fontSizes.heading }}>
          Do any of the following apply to you?
        </h2>

        <p className="text-gray-500 xs:mb-2 text-left md:text-center md:mb-8" style={{ fontSize: fontSizes.subheading }}>
          It's important to understand your health history to determine if you're eligible.
        </p>

        <button
          className={`w-full mb-4 xs:mb-4 md:mb-4 mt-2 px-4 py-3 xs:text-left md:text-center border-2 rounded-lg transition-colors ${
            isNoneSelected ? "bg-blue-100 border-blue-500" : "border-gray-300 hover:bg-gray-100"
          }`}
          onClick={() => handleFieldUpdate("No exsisting disqualifying factors")}
          style={{ fontSize: fontSizes.buttonText }}
        >
          None of these
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {disqualifyingConditions.map((condition, index) => (
            <button
              key={index}
              className={`w-full px-4 py-3 text-left border-2 rounded-lg text-[#0B1838] transition-colors ${
                selectedHistory === condition ? "bg-blue-100 border-blue-500" : "border-gray-300 hover:bg-gray-100"
              }`}
              onClick={() => handleFieldUpdate(condition)}
              style={{ fontSize: fontSizes.buttonText }}
            >
              {condition}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HealthHistory;