import React, { useMemo, useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFormValue } from "../lib/store/actions/formAction";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { useNavigate } from "react-router-dom";
import useResponsiveFonts from "../hooks/useResponsiveFonts";
import SectionActionButton from "../components/microcomponents/SectionActionButton";

const healthConditions: string[] = [
  "Hypertension",
  "Hyperlipidemia",
  "Impaired Fasting Glucose/Pre-diabetes",
  "Fatty Liver Disease",
  "Obstructive Sleep Apnea",
  "History of Osteoarthritis",
  "Coronary Artery Disease",
  "Peripheral Vascular Disease",
  "Polycystic Ovary Syndrome (PCOS)",
  "History of bariatric surgery",
];

interface ComorbiditiesFormProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const ComorbiditiesForm: React.FC<ComorbiditiesFormProps> = ({ availableHeight, availableWidth }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state for selected comorbidities
  const [selectedComorbidities, setSelectedComorbidities] = useState<string[]>(form.comorbidities || []);
  const handleSubmit = useCallback(() => {
    // Update the form state with selected comorbidities
    dispatch(setFormValue({ field: "comorbidities", value: selectedComorbidities }));

    const bmi = form.currentBMI;
    if (bmi === undefined || bmi === null) {
      alert("BMI is not calculated. Please complete the BMI calculator first.");
      return;
    }

    if (bmi >= 30) {
      navigate("/antiobesity");
    } else if (bmi >= 27 && bmi < 30) {
      if (selectedComorbidities.length >= 1) {
        navigate("/antiobesity");
      } else {
        navigate("/disqualified");
      }
    } else if (bmi >= 25 && bmi < 27) {
      navigate("/antiobesity");
    } else {
      navigate("/disqualified");
    }
  }, [dispatch, form.currentBMI, navigate, selectedComorbidities]);

  const handleNoneSelected = useCallback(() => {
    setSelectedComorbidities([]);
    handleSubmit(); 
  }, [handleSubmit]);


  const toggleComorbidities = useCallback((condition: string) => {
    setSelectedComorbidities((prev) => {
      if (prev.includes(condition)) {
        return prev.filter((item) => item !== condition);
      } else {
        return [...prev, condition];
      }
    });
  }, []);

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32,
      subheading: 20,
      buttonText: 18,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 19,
      subheading: 14,
      buttonText: 14,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, 800, 1200, 600, 400, 12, 72, 768);

  return (
    <div
      className="flex flex-col items-center xs:mt-6 md:mt-16 xs:mb-[5rem] md:mb-0"
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div className="xs: w-[90%] md:w-full text-center lg:max-w-6xl mx-auto">
        {/* Heading */}
        <h2 className="font-bold text-left md:text-center text-[#0B1838]" style={{ fontSize: fontSizes.heading }}>
        Do any of the following apply to you?
        </h2>

        {/* Subheading/Paragraph */}
        <p className="text-gray-500 xs:mb-2 md:mb-4 text-left md:text-center" style={{ fontSize: fontSizes.subheading }}>
        It's important to understand your health history to determine if you're eligible.
        </p>

       {/* "None of these" Option */}
       {selectedComorbidities.length === 0 && (
            <button
            className={`w-full mb-4 mt-2 px-4 py-3 text-left md:text-center border-2 rounded-lg transition-colors ${
              selectedComorbidities.length === 0
                ? 'bg-[#E6EAF580] border-[#0B1838] text-[#333]'
                : "bg-gray-50 border-gray-300 hover:bg-gray-100 text-gray-800" 
            }`}
              onClick={handleNoneSelected}
              style={{ fontSize: fontSizes.buttonText }}
            >
              None of these
            </button>
        )}

        {/* Comorbidities Selection */}
        <div className="xs:mb-[2.5rem] md:mb-6 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {healthConditions.map((condition, index) => (
              <button
                key={index}
                className={`w-full px-4 py-3 text-[#0B1838] text-left border-2 rounded-lg transition-colors ${
                  selectedComorbidities.includes(condition) ? 'bg-[#E6EAF580] border-[#0B1838] text-[#333]' : "bg-gray-50 border-gray-300 hover:bg-gray-100"
                }`}
                onClick={() => toggleComorbidities(condition)}
                style={{ fontSize: fontSizes.buttonText }}
              >
                {condition}
              </button>
            ))}
          </div>
        </div>

        {selectedComorbidities.length > 0 && (
          <SectionActionButton text="Next"  onClick={handleSubmit} style={{ fontSize: fontSizes.buttonText , position: "center", bottom: "2rem" }} />
        )}
      </div>
    </div>
  );
};

export default ComorbiditiesForm;
