import React, { useState, useCallback, Suspense, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import { debounce } from "lodash";
import emailValidation from "./validations/emailValidation";
import { useNavigate } from "react-router-dom";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const genderOptions = ["Choose your gender", "Male", "Female"];

interface InfoProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const Info: React.FC<InfoProps> = ({ availableHeight, availableWidth, marginTop }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [dateError, setDateError] = useState("");

  const handFieldUpdate = useCallback(
    (field: string, value: string) => {
      const payload = { field, value };
      dispatch(setFormValue(payload));
    },
    [dispatch]
  );

  const validateName = (name: string) => {
    const regex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
    return name.length >= 2 && regex.test(name);
  };

  const validateDate = (date: string) => {
    const today = new Date();
    const selectedDate = new Date(date);

    if (selectedDate > today) {
      setDateError("Date of birth cannot be in the future.");
      return false;
    }

    const minAgeDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    if (selectedDate > minAgeDate) {
      setDateError("You must be at least 16 years old.");
      return false;
    }

    setDateError(""); // Clear error if valid
    return true;
  };

  const validateEmail = (email: any) => {
    const isValid = emailValidation(email);
    setEmailError(!isValid ? "Please enter a valid email address" : "");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidateEmail = useCallback(
    debounce((email: string) => {
      validateEmail(email);
    }, 300),
    []
  );

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("firstName", value);
    if (!validateName(value)) {
      setFirstNameError("First name must be at least 2 characters and contain only letters.");
    } else {
      setFirstNameError("");
    }
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("lastName", value);
    if (!validateName(value)) {
      setLastNameError("Last name must be at least 2 characters and contain only letters.");
    } else {
      setLastNameError("");
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    if (validateDate(selectedDate)) {
      handFieldUpdate("dateOfBirth", selectedDate);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("email", value);
    debouncedValidateEmail(value);
  };

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (!emailError && !firstNameError && !lastNameError && !dateError && form.firstName && form.email && form.dateOfBirth) {
        navigate("/greetings");
      } else {
        alert("Please ensure all details are valid.");
      }
    },
    [emailError, firstNameError, lastNameError, dateError, form, navigate]
  );

  const desktopFontConfig = useMemo(
    () => ({
      heading: 30, // Desktop font size for <h2>
      buttonText: 19, // Desktop font size for buttons
      label: 16,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 24, // Mobile font size for <h2>
      buttonText: 14, // Mobile font size for buttons
      label: 13,
    }),
    []
  );

  // Use the custom hook to get scaled font sizes
  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );

  return (
    <div className="flex items-center justify-center xs:px-8 md:px-0">
      <div className="md:max-w-xl lg:max-w-6xl w-full xs:my-12 md:mt-16">
        <h2 className="xs:text-xl md:text-3xl font-medium xs:text-left md:text-center mb-8 text-[#0B1838]" style={{ fontSize: fontSizes.heading }}>
          Please share your information to get started.
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="firstName" className="block text-sm text-[#0B1838] xs:text-sm xl:text-lg  camelCase  mb-2" style={{ fontSize: fontSizes.label }}>
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                value={form.firstName}
                onChange={handleFirstNameChange}
                className={`w-full xs:px-3 text-[#0B1838] md:px-6 py-2 border  rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  firstNameError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                placeholder="Enter your first name"
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {firstNameError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {firstNameError}
                </p>
              )}
            </div>

            <div>
              <label htmlFor="lastName" className="block text-sm text-[#0B1838] xs:text-sm xl:text-lg  camelCase  mb-2" style={{ fontSize: fontSizes.label }}>
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                value={form.lastName}
                onChange={handleLastNameChange}
                className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  lastNameError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                placeholder="Enter your last name"
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {lastNameError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {lastNameError}
                </p>
              )}
            </div>
          </div>

          <div>
            <label htmlFor="dateOfBirth" className="block text-sm text-[#0B1838] xs:text-sm xl:text-lg  camelCase  mb-2" style={{ fontSize: fontSizes.label }}>
              Date of Birth
            </label>
            <input
              id="dateOfBirth"
              onChange={handleDateChange}
              className={`w-full xs:px-3 md:px-6 py-2 text-[#0B1838] border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                dateError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
              }`}
              type="date"
              max={new Date().toISOString().split("T")[0]}
              value={form.dateOfBirth}
              required
              style={{ fontSize: fontSizes.inputText, background: "#FBFBFB", WebkitAppearance: "none", width: "100%" }}
              dir="ltr"
            />
            {dateError && (
              <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                {dateError}
              </p>
            )}
          </div>

          <div className="relative ">
            <label htmlFor="gender" className="block text-sm text-[#0B1838] xs:text-sm xl:text-lg  camelCase  mb-2" style={{ fontSize: fontSizes.label }}>
              Gender Assigned At Birth
            </label>
            <select
              id="gender"
              className="w-full xs:px-3 md:px-6 py-2 border text-[#0B1838] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none"
              onChange={(e) => handFieldUpdate("gender", e.target.value)}
              value={form.gender}
              style={{
                fontSize: fontSizes.inputText,
                borderRadius: "10px",
                paddingRight: "2.5rem",
                background: "#FBFBFB",
              }}
            >
              {genderOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {/* Custom arrow */}
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center xs:pr-[1rem] md:pr-[1.6rem]" style={{ top: "40%" }}>
              <svg className="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block xs:text-sm xl:text-lg text-[#0B1838]  camelCase  mb-2" style={{ fontSize: fontSizes.label }}>
              Email
            </label>
            <input
              id="email"
              type="email"
              value={form.email}
              onChange={handleEmailChange}
              placeholder="Enter your email address"
              className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                emailError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
              }`}
              required
              style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                {emailError}
              </p>
            )}
          </div>

          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <SectionActionButton text="Next" onClick={handleSubmit} fontSize={fontSizes.buttonText} className="w-full" />
            </Suspense>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Info;
