import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SectionActionButton = ({ text, ...props }: any) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.disabled) {
      return;
    }
    if (props.buttonnavurl) {
      navigate(props.buttonnavurl);
    } else {
      props.onClick?.();
    }
  };

  return (
    <Button
      px={10}
      py={12}
      borderRadius="16px"
      transition="background 0.3s ease, color 0.3s ease"
      fontFamily="Sofia Pro"
      className={`xs:w-full mx-auto md:m-0 lg:w-[80%] p-0  font-bold sm:fixed xs:my-4 md:mb-0 md:mt-8 bottom-0 ${
        !props.disabled ? "bg-blue-500 text-[#0B1838]" : "bg-gray-300 text-gray-500 cursor-not-allowed"
      }`}
      bg={props.disabled ? "rgba(255, 255, 255, 0.20)" : "rgba(255, 255, 255, 0.50)"}
      _hover={{
        background: !props.disabled ? "linear-gradient(106deg, #2B3A67 64.26%, #F0AB35 117.45%)" : "",
        color: !props.disabled ? "white" : "text-[#0B1838]",
      }}
      border="1px solid #2B3A67"
      cursor={props.disabled ? "not-allowed" : "pointer"}
      {...props}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default SectionActionButton;
