import { Box } from "@chakra-ui/react";
import SectionMainText from "../microcomponents/SectionMainText";
import SectionActionButton from "../microcomponents/SectionActionButton";
import SectionSpliter from "../microcomponents/SectionSpliter";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import useResponsiveFonts from "../../hooks/useResponsiveFonts";

const SectionLeft = ({ mainText, description, buttonText, imageMobile, buttonnavurl, isCheckboxVisible, availableHeight, availableWidth }: any) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

    const desktopFontConfig = useMemo(
    () => ({
      mainText: 150,    // Desktop font size for <h2>
      description: 22,   // Desktop font size for <p>
      button: 19,   // Desktop font size for buttons
    }),
    []
  );
  
  const mobileFontConfig = useMemo(
    () => ({
      mainText: 28,      // Mobile font size for <h2>
      description: 11,   // Mobile font size for <p>
      button: 14,   // Mobile font size for buttons
    }),
    []
  );
  
  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800,  // baseHeight for desktop
    1200, // baseWidth for desktop
    600,  // baseHeight for mobile
    400,  // baseWidth for mobile
    12,   // minFontSize
    72,   // maxFontSize
    768   // mobile breakpoint
  );

  const onButtonClick = () => {
    navigate(buttonnavurl);
  };
  return (
    <div style={{ height: availableWidth > 601 && availableWidth < 1025 ? "auto" : availableWidth < 600 ? "100%" : availableHeight, minHeight: availableHeight}} className="flex lg:flex-1 flex-col sm:items-center lg:items-start justify-between xs:mt-2 2xl:mt-10 3xl:mt-20">
      <SectionMainText text={mainText} fontSize={fontSizes.mainText} />
      <div className="flex flex-1 flex-col items-start justify-start xs:mt-4 md:mt-10 xs:mr-0 xl:mr-20">
        <Box display="flex" flexDirection="row" gap={10} alignItems="start">
          {isCheckboxVisible && (
            <input
              style={{ lineHeight: `${1. * parseFloat(fontSizes.description)}px` }}
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className="md:scale-[130%] xs:mt-0 md:mt-[0.3rem]"
              id="scales"
              name="scales"
            />
          )}
          <span style={{ fontSize: fontSizes.description, lineHeight: `${1.3 * parseFloat(fontSizes.description)}px` }} className=" text-[#0B1838] font-sofia">
            {description}
          </span>
        </Box>
        <SectionActionButton disabled={!isChecked && isCheckboxVisible} text={buttonText} onClick={onButtonClick} fontSize={fontSizes.button} />
        <div className="md:hidden xs:flex relative w-full h-full justify-end items-end">
          <img src={imageMobile} alt="Celly Health" className="object-contain w-full " />
        </div>
       
      </div>
    </div>
  );
};

const SectionRight = ({ imageUrl }: any) => {
  return (
    <div className="sm:flex justify-center items-end w-full h-full xs:hidden">
      <img src={imageUrl} alt="Celly Health" className="max-w-full max-h-[95%] object-contain sm:scale-150 lg:scale-[100%] sm:mb-[6rem] lg:mb-[0rem]" />
    </div>
  );
};

const ImageWithText = ({ mainText, imageUrl, imageMobile, description, buttonText, marginTop, availableHeight, className, availableWidth, buttonnavurl, isCheckboxVisible = true, imageScale }: any) => {
  return (
    <div className="md:overflow-hidden overflow-auto h-full md:h-screen" style={{ height: availableWidth < 600 ? "100%" : availableHeight - 38 }}>
      <SectionSpliter
        availableHeight={availableHeight - 38}
        availableWidth={availableWidth}
        marginTop={marginTop}
        className={className}
        sectionLeft={
          <SectionLeft
            mainText={mainText}
            isCheckboxVisible={isCheckboxVisible}
            description={description}
            buttonText={buttonText}
            buttonnavurl={buttonnavurl}
            imageMobile={imageMobile}
            availableHeight={availableHeight - 38}
            availableWidth={availableWidth}
          />
        }
        sectionRight={<SectionRight imageUrl={imageUrl} imageScale={imageScale} availableHeight={availableHeight - 38}  />}
      />
    </div>
  );
};

export default ImageWithText;
