const SectionSpliter = ({ sectionRight, sectionLeft, availableHeight, availableWidth, className, marginBottom }: any) => {
  return (
    <div className={`${marginBottom ? marginBottom : ""} grid grid-cols-1 md:grid md:grid-cols-2 h-full`} style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
      <div className="flex flex-col sm:items-center sm:justify-center h-full pl-8 xs:px-10  lg:pl-10 xl:px-20 z-10 3xl:mt-8 " style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
        {sectionLeft}
      </div>

      <div className={`${className ? className : ""} flex flex-col justify-end h-full pl-8 xs:px-10 sm:px-0 lg:px-10 md:pl-10 lg:pl-10 xl:pl-20`} style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
        {sectionRight}
      </div>
    </div>
  );
};

export default SectionSpliter;