import { shallowEqual, useSelector } from "react-redux";
import ImageWithText from "../components/macrocomponents/ImageWithText";
import Image from "../assets/greet.webp"
import { selectFormState } from "../lib/store/selectors/formSelector";

export default function Greetings({availableHeight, marginTop, availableWidth}: any) {
  const form = useSelector(selectFormState, shallowEqual);

  return (
    <ImageWithText
      mainText={`Nice to meet you, ${form.firstName}`}
      imageUrl={Image}
      isCheckboxVisible={false}
      imageMobile={Image}
      description="We're Celly Health - a team of board - certified physicians and weight loss specialists who care about you."
      buttonText="Next"
      availableHeight={availableHeight}
      availableWidth={availableWidth}
     marginTop={marginTop}
      buttonnavurl="/motivation"
      className="xs:hidden md:block"
    />
  );
}

