import ImageWithText from "../components/macrocomponents/ImageWithText";
import Image from "../assets/image1web.png";
import Imagemob from "../assets/mobilehome.webp";
export default function Home({ availableHeight, marginTop, availableWidth }: any) {
  return (
    <ImageWithText
      mainText="Your weight loss journey starts here"
      imageUrl={Image}
      imageMobile={Imagemob}
      buttonnavurl="/glp"
      description={
        <>
          By clicking <span style={{ color: "#0B1838" }} className="font-bold">‘Continue,’</span> you agree that Celly Health may use your responses to personalize your experience and for other purposes as described in our{" "}
          <a href="https://www.cellyhealth.com/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "#0B1838", textDecoration: "underline" }}>
            Privacy Policy
          </a>
          . Responses prior to account creation will not be used as part of your medical assessment.
        </>
      }
      buttonText="Continue"
      availableHeight={availableHeight}
      availableWidth={availableWidth}
      marginTop={marginTop}
      className="xs:hidden md:block"
    />
  );
}
