/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo } from "react";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import { setFormValue } from "../lib/store/actions/formAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const BMICalculator = ({ availableHeight, availableWidth }: any) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const [height, setHeight] = useState("");
  const [url, setUrl] = useState("");

  const handFieldUpdate = useCallback(
    (field: string, value: any) => {
      const payload = { field, value };
      dispatch(setFormValue(payload));
    },
    [dispatch]
  );

  const heightFt = form.heightFt;
  const heightIn = form.heightIn;
  const currentWeight = form.currentWeight;
  const goalWeight = form.goalWeight;
  const currentBMI = form.currentBMI;
  const goalBMI = form.goalBMI;

  const [isInputValid, setIsInputValid] = useState(false);

  const setBMI = (value: any) => {
    handFieldUpdate("currentBMI", value);
  };

  const setgoalBMI = (value: any) => {
    handFieldUpdate("goalBMI", value);
  };

  const calculateBMI = (currentWeight: any) => {
    if (heightFt && heightIn && currentWeight) {
      const totalHeightInInches = parseFloat(heightFt) * 12 + parseFloat(heightIn);
      const bmiValue = (parseFloat(currentWeight) / Math.pow(totalHeightInInches, 2)) * 703;
      return bmiValue.toFixed(2);
    } else {
      return null;
    }
  };

  useEffect(() => {
    const heightValid = parseFloat(heightFt) > 0 && parseFloat(heightIn) >= 0;
    const weightValid = parseFloat(currentWeight) > 0;

    if (heightValid && weightValid) {
      setIsInputValid(true);
      setBMI(calculateBMI(currentWeight));
      const height = `${heightFt} ft ${heightIn} in`;
      setHeight(height);
    } else {
      setIsInputValid(false);
      setBMI(null);
    }
  }, [calculateBMI, heightFt, heightIn, currentWeight]);

  useEffect(() => {
    const goalWeightValid = parseFloat(goalWeight) > 0;
    if (goalWeightValid) {
      setgoalBMI(calculateBMI(goalWeight));
    } else {
      setgoalBMI(null);
    }
  }, [goalWeight]);

  useEffect(() => {
    if (currentBMI >= 25) {
      setUrl("/healthhistory");
    } else {
      setUrl("/disqualified");
    }
  }, [currentBMI]);

  const removeHeight = () => {
    setHeight("");
    handFieldUpdate("heightFt", "");
    handFieldUpdate("heightIn", "");
  };

  const desktopFontConfig = useMemo(
    () => ({
      heading: 29, // Base font size in px for <h2>
      label: 25, // Base font size in px for labels
      inputText: 21, // Base font size in px for input text
      buttonText: 19, // Base font size in px for button text
      errorText: 16, // Desktop font size for buttons
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 20, // Base font size in px for <h2>
      label: 17, // Base font size in px for labels
      inputText: 17, // Base font size in px for input text
      buttonText: 18, // Base font size in px for button text
      errorText: 16, // Mobile font size for buttons
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobil
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );
  return (
    <div className="flex flex-col  xs:mt-8 md:mt-16 xs:px-8 md:px-[3rem] lg:px-0 items-center m-auto max-w-6xl" style={{ fontSize: fontSizes.heading }}>
      <h2 style={{ fontSize: fontSizes.heading, lineHeight: 1.2 }} className="font-bold  text-gray-800 text-center md:max-w-xl lg:max-w-6xl">
        Next, we'll calculate your Body Mass Index (BMI).
      </h2>

      {/* Height and Weight Inputs */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-6 md:gap-12 w-full xs:py-4 md:py-8">
        {/* Height Input */}
        <div className="flex flex-col items-center">
          <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
            Height
          </label>
          {!(heightFt && heightIn && currentWeight) ? (
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="Feet"
                value={heightFt === 0 ? "" : heightFt}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValue = parseInt(value, 10);
                  if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 9) {
                    handFieldUpdate("heightFt", numericValue);
                  } else if (value === "") {
                    handFieldUpdate("heightFt", "");
                  }
                }}
                className="h-12 sm:w-24 md:w-28 xs:w-1/2 text-xl text-center px-3 py-2 placeholder-gray-400 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                placeholder="Inches"
                value={heightIn === 0 ? "" : heightIn}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValue = parseInt(value, 10);
                  if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 11) {
                    handFieldUpdate("heightIn", numericValue);
                  } else if (value === "") {
                    handFieldUpdate("heightIn", "");
                  }
                }}
                className="h-12 sm:w-24 md:w-28 xs:w-1/2 text-xl text-center px-3 py-2 placeholder-gray-400 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                style={{ fontSize: fontSizes.inputText }}
              />
            </div>
          ) : (
            <input
              type="text"
              value={height}
              onClick={removeHeight}
              style={{ fontSize: fontSizes.inputText }}
              readOnly
              className="xs:w-1/2 h-12 md:w-52 md:w-full text-xl text-center px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none cursor-pointer"
            />
          )}
        </div>

        {/* Weight Input */}
        <div className="flex flex-col items-center">
          <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
            Weight
          </label>
          <input
            placeholder="lbs"
            value={currentWeight === 0 ? "" : currentWeight}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/\D/g, "");
              value = value.slice(0, 3);
              let numericValue = parseInt(value, 10);
              if (!isNaN(numericValue)) {
                if (numericValue > 900) {
                  numericValue = 900;
                }
                handFieldUpdate("currentWeight", numericValue);
              } else {
                handFieldUpdate("currentWeight", "");
              }
            }}
            type="number"
            className={`h-12 ${heightFt && heightIn  ? "md:w-full" : "md:w-52" } xs:w-1/2 text-xl text-center px-3 py-2 placeholder-gray-400 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            style={{ fontSize: fontSizes.inputText }}
          />
        </div>

        {/* BMI Display */}
        {currentBMI && currentWeight.toString().length >= 2 && (
          <>
            <div className="hidden md:flex md:mt-[2.5rem] items-center text-2xl font-bold">=</div>
            <div className="flex flex-col items-center">
              <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
                BMI
              </label>
              <div
                  className="xs:w-1/2 text-center md:w-full px-4 py-2 text-xl rounded-lg text-white bg-[linear-gradient(109deg,_#2B3A67_70.08%,_#F0AB35_118.17%)]"
                style={{
                  fontSize: fontSizes.inputText,
                }}
              >
                {currentBMI}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Goal Weight Section */}
      {currentBMI && (
        <div className="mt-8 xs:w-full md:w-auto">
          <h2 style={{ fontSize: fontSizes.heading, lineHeight: 1.2 }} className="font-bold  text-gray-800 text-center">
            Now tell us your Goal Weight.
          </h2>
          <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-6 md:gap-12 xs:py-4 md:py-8">
            {/* Goal Weight Input */}
            <div className="flex flex-col items-center">
              <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
                Goal Weight
              </label>
              <input
                placeholder="lbs"
                value={goalWeight === 0 ? "" : goalWeight}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/\D/g, "");
                  value = value.slice(0, 3);
                  let numericValue = parseInt(value, 10);
                  if (!isNaN(numericValue)) {
                    if (numericValue > 900) {
                      numericValue = 900;
                    }
                    if (numericValue >= currentWeight) {
                      numericValue = currentWeight - 1;
                    }
                    handFieldUpdate("goalWeight", numericValue);
                  } else {
                    handFieldUpdate("goalWeight", "");
                  }
                }}
                type="number"
                className={`h-12 ${heightFt && heightIn  ? "md:w-full" : "md:w-52" } xs:w-1/2 text-xl text-center px-3 py-2 placeholder-gray-400 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                style={{ fontSize: fontSizes.inputText }}
              />
            </div>
            {goalBMI && !isNaN(goalBMI) && goalWeight.toString().length >= 2 && (
              <>
                <div className="hidden md:flex items-center md:mt-[2.5rem] text-2xl font-bold">=</div>
                <div className="flex flex-col items-center">
                  <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
                    BMI
                  </label>
                  <div className="px-4 py-2 text-xl rounded-lg w-1/2 text-center md:w-full text-white bg-[linear-gradient(109deg,_#2B3A67_70.08%,_#F0AB35_118.17%)]">{goalBMI}</div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <SectionActionButton
        text="Next"
        disabled={!isInputValid}
        className={`xs:w-full md:w-full xs:mb-[7rem] md:mb-0 text-center font-bold ${isInputValid ? "bg-blue-500 text-[#0B1838]" : "bg-gray-300 text-gray-500 cursor-not-allowed"}`}
        bg={isInputValid ? "rgba(255, 255, 255, 0.20)" : "rgba(255, 255, 255, 0.50)"}
        style={{ fontSize: fontSizes.buttonText }}
        _hover={{
          background: isInputValid ? "linear-gradient(90deg, #2B3A67 07%, #F0AB35 100%)" : "",
          textColor: isInputValid ? "white" : "",
          border: "none",
        }}
        buttonnavurl={url}
      />
    </div>
  );
};

export default BMICalculator;
