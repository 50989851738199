import hipaa from "../../assets/SealofCompliance.svg";
import seal from "../../assets/PCABGoldSteal.svg";
import legiticon from "../../assets/legitscript.svg";
import React from "react";

const Footer = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <footer ref={ref} className="justify-between z-20 fixed bottom-0 w-full items-center sm:px-[3rem] lg:px-[5rem] md:py-4 xs:h-8 md:h-auto bg-gradient-to-r from-yellow-400 to-orange-500 md:flex">
      <div className="flex justify-between flex-1 space-x-2">
        <div className="gap-6 flex-row sm:flex xs:hidden">
          <img src={legiticon} alt="legit badge" className=" rounded-full" />
          <img src={seal} alt="badge" className="rounded-full" />
          <img src={hipaa} alt="HIPAA" className="rounded-full" />
        </div>
        <h1 className="text-white md:text-2xl md:mt-4 font-normal xs:hidden md:flex">Healthcare the way it should be!</h1>
      </div>
    </footer>
  );
});

export default Footer;