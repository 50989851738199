import SectionActionButton from "../components/microcomponents/SectionActionButton";
import image from "../assets/newsWeb.webp";
import SectionSpliter from "../components/microcomponents/SectionSpliter";
import imageMobile from "../assets/greatnewsmobile.webp";
import useResponsiveFonts from "../hooks/useResponsiveFonts";
import { useMemo } from "react";

const SectionLeft = ({ availableHeight, availableWidth }: any) => {
  const desktopFontConfig = useMemo(
    () => ({
      mainText: 150, // Desktop font size for <h2>
      description: 30, // Desktop font size for <p>
      button: 19, // Desktop font size for buttons
      subtext: 22,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      mainText: 28, // Mobile font size for <h2>
      description: 14, // Mobile font size for <p>
      button: 14, // Mobile font size for buttons
      subtext: 11,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );

  return (
    <div style={{ height: availableWidth > 601 && availableWidth < 1025 ? "auto" : availableWidth < 600 ? "100%" : availableHeight }} className="flex lg:flex-1 flex-col sm:items-center lg:items-start justify-between xs:mt-2 2xl:mt-10 3xl:mt-20">
      <div className="text-left">
        <h1 style={{ fontSize: fontSizes.mainText, lineHeight: 1.2 }} className="font-bold text-[#0B1838] xs:mb-2 md:mb-4">
          Great News!
        </h1>

        <div className="flex flex-col w-full">
          <h2 style={{ fontSize: fontSizes.description, lineHeight: 1.1 }} className="bg-gradient-to-r from-[#F06735] to-[#F8E231] text-transparent bg-clip-text xs:mb-2 md:mb-6">
            We have some top-rated providers licensed to care for you in your area.
          </h2>

          <p style={{ fontSize: fontSizes.subtext, lineHeight: 1.1 }} className="text-[#0B1838] ">
            The following questions help us determine if weight loss medications are right for you and tailor your plan appropriately.
          </p>

          <SectionActionButton text="Next" buttonnavurl="/bmi" fontSize={fontSizes.button} />

          <div className="md:hidden xs:block xs:pt-8">
            <img src={imageMobile} alt="Celly Health" className="object-cover w-full h-full xs:scale-[122%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionRight = ({ image }: any) => {
  return (
    <div className="sm:flex justify-center items-end w-full h-full xs:hidden">
      <img src={image} alt="A graph showing the effectiveness of GLP-1 meds" className="hidden sm:block mt-5 md:object-contain" />
    </div>
  );
};

const GreatNews = ({ mainText, imageUrl, imageMobile, description, buttonText, marginTop, availableHeight, className, availableWidth, buttonnavurl, isCheckboxVisible = true, imageScale }: any) => {
  className = className ? "!pr-0 " + className : "!pr-0";
  return (
    <div className="md:overflow-hidden overflow-auto h-full md:h-screen" style={{ height: availableWidth < 600 ? "100%" : availableHeight - 38 }}>
      <SectionSpliter
        availableHeight={availableHeight - 38}
        availableWidth={availableWidth}
        marginTop={marginTop}
        className={className}
        sectionLeft={
          <SectionLeft
            mainText={mainText}
            isCheckboxVisible={isCheckboxVisible}
            description={description}
            buttonText={buttonText}
            buttonnavurl={buttonnavurl}
            imageMobile={imageMobile}
            availableHeight={availableHeight - 38}
            availableWidth={availableWidth}
          />
        }
        sectionRight={<SectionRight image={image} imageScale={imageScale} availableHeight={availableHeight} />}
      />
    </div>
  );
};

export default GreatNews;
