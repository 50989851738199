import SectionActionButton from "../components/microcomponents/SectionActionButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import Image from "../assets/qualify.webp";
import { setFormReset } from "../lib/store/actions/formAction";
// import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

export default function Qualified({ availableHeight, availableWidth }: any) {


  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();

  const desktopFontConfig = useMemo(
    () => ({
      mainText: 150, // Base font size in px for <h1>
      subText: 50, // Base font size in px for <p> acting as <h2>
      bodyText: 35, // Base font size in px for regular <p>
      button: 19, // Base font size in px for buttons
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      mainText: 40, // Base font size in px for <h1>
      subText: 30, // Base font size in px for <p> acting as <h2>
      bodyText: 16, // Base font size in px for regular <p>
      button: 18, // Base font size in px for buttons
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );


  const handleCreateAccount = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const registrationResponse = await fetch(`${apiBaseUrl}/api/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form),  
      });
  
      const registrationData = await registrationResponse.json();
  
      if (!registrationData.success) {
        throw new Error("Failed to create account");
      }
  

      dispatch(setFormReset());
      window.location.href = "https://schedule.cellyhealth.com";
  
    } catch (error) {
      console.error("Error creating account:", error);
    }
  };


  return (
    <div className="xs:h-auto md:h-screen grid grid-cols-1 lg:grid-cols-2  xs:mt-16 md:mt-0" style={{ height: availableWidth < 600 ? "100%" : availableHeight - 38 }}>
      <div className="flex flex-col justify-center items-start xs:px-10 lg:pl-20 bg-white sm:mt-12 lg:mt-0">
        <div className="w-full">
          <h1 style={{ fontSize: fontSizes.mainText, lineHeight: 1.2 }} className="font-bold text-[#0B1838]">
            Great news, <span>{`${form.firstName}`}</span>!
          </h1>

          <p style={{ fontSize: fontSizes.subText, lineHeight: 1.3 }} className="bg-gradient-to-r from-[#F06735] to-[#F8E231] text-transparent bg-clip-text ">
            You're eligible.
          </p>

          <p style={{ fontSize: fontSizes.bodyText, lineHeight: 1.5 }} className="md:mt-8 text-[#0B1838]">
            Next, let's create your account.
          </p>

          <SectionActionButton
            onClick={handleCreateAccount}
            text="Create Account"
            className="mt-4 px-6 py-3 text-lg w-full border md:w-[80%] border-[#333] text-[#0B1838] rounded-md hover:bg-gradient-to-r from-[#F8E231] to-[#F06735] hover:text-white"
            fontSize={fontSizes.button}
            
            disabled={false}
          />
        </div>
      </div>

      <div className="relative">
        <img src={Image} alt="women smiling" className="w-full h-full mt-5 object-cover md:mb-[-5rem] lg:mb-[-2.8rem] xl:mb-0" />
      </div>
    </div>
  );
}
