import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavigationContextProps {
  history: string[];
  goBack: () => void;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [history, setHistory] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setHistory((prev) => {
      if (prev[prev.length - 1] !== location.pathname) {
        return [...prev, location.pathname];
      }
      return prev;
    });
  }, [location]);

  const goBack = () => {
    if (history.length > 1) {
      setHistory((prev) => prev.slice(0, -1)); 
      navigate(history[history.length - 2]); 
    } else {
      navigate('/'); 
    }
  };

  return (
    <NavigationContext.Provider value={{ history, goBack }}>
      {children}
    </NavigationContext.Provider>
  );
};