import { useEffect, useState } from "react";

const fontAnimation = {
  lineHeight: "normal",
};

const SectionMainText = ({ text, fontSize }: any) => {
  const [maintext, setmaintext] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setmaintext(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, 30);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, text]);

  return (
    <h1 
      style={{fontSize: fontSize,  ...fontAnimation, lineHeight: `${1.2 * parseFloat(fontSize)}px`}}
      className="bg-gradient-to-r from-[#F06735] to-[#F8E231] bg-clip-text text-transparent xs:mt-8 md:mt-0 font-bold">
      {maintext}
    </h1>
  );
};

export default SectionMainText;
