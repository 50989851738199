import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectFormState } from '../lib/store/selectors/formSelector';
import { setFormValue } from '../lib/store/actions/formAction';
import useResponsiveFonts from '../hooks/useResponsiveFonts'; 

const motivations = [
  'I want to keep the weight off this time',
  'I want to improve my health',
  'I want to have more energy',
  'I want to change my appearance',
  'I want to participate in activities that I currently can\'t do',
];

export default function Motivation({ availableHeight, availableWidth, marginTop }: any) {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32,      // Desktop font size for <h2>
      button: 22,   // Desktop font size for <p>
      buttonText: 18,   // Desktop font size for buttons
    }),
    []
  );
  
  const mobileFontConfig = useMemo(
    () => ({
      heading: 24,      // Mobile font size for <h2>
      button: 20,   // Mobile font size for <p>
      buttonText: 14,   // Mobile font size for buttons
    }),
    []
  );
  
  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800,  // baseHeight for desktop
    1200, // baseWidth for desktop
    600,  // baseHeight for mobile
    400,  // baseWidth for mobile
    12,   // minFontSize
    72,   // maxFontSize
    768   // mobile breakpoint
  );


  const selectedQuestion = motivations.findIndex(
    (question) => question === form.motivation
  );

  const handleSelection = (selection: string) => {
    const payload = { field: 'motivation', value: selection };
    dispatch(setFormValue(payload));
    navigate('/change');
  };

  return (
    <div className="flex flex-col items-center xs:mt-12 md:mt-24 xs:px-8 md:px-0">
      <h2
        style={{ fontSize: fontSizes.heading, lineHeight: 1.3 }}
        className="font-bold xs:text-left md:text-center mb-6"
      >
        What is your top motivation for losing weight?
      </h2>

      {/* Motivation Buttons */}
      <div className="w-full max-w-xl space-y-6">
        {motivations.map((motivation, index) => (
          <button
            key={index}
            className={`w-full px-4 py-4 text-left border-2 rounded-lg transition-colors ${
              index === selectedQuestion
                ? 'bg-[#E6EAF580] border-[#0B1838] text-[#333]'
                : 'border-gray-300 hover:bg-gray-100'
            }`}
            onClick={() => handleSelection(motivation)}
          >
            <span style={{ fontSize: fontSizes.buttonText }} className="block">
              {motivation}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}