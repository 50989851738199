import React, { useEffect, useMemo, useRef } from "react";
import SectionSpliter from "../components/microcomponents/SectionSpliter";
import SectionMainText from "../components/microcomponents/SectionMainText";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const GraphVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.playbackRate = 0.7;
      video.play();

      const handleTimeUpdate = () => {
        if (video.currentTime >= 10) {
          video.currentTime = 0;
          video.play();
        }
      };

      video.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, []);

  return (
    <div className="w-full">
      <video ref={videoRef} src="/graphglp.mp4" muted playsInline autoPlay>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const SectionLeft = ({ availableHeight, availableWidth }: any) => {
    const desktopFontConfig = useMemo(
    () => ({
      mainText: 150,// Desktop font size for <h2>
      description: 22, // Desktop font size for <p>
      button: 19, // Desktop font size for buttons
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      mainText: 27, // Mobile font size for <h2>
      description: 11, // Mobile font size for <p>
      button: 14, // Mobile font size for buttons
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );
 
  return (
    <div style={{ height: availableWidth > 601 && availableWidth < 1025 ? "auto" : availableWidth < 600 ? "100%" : availableHeight }} className="flex lg:flex-1 flex-col sm:items-center lg:items-start md:mt-16">
      <SectionMainText fontSize={fontSizes.mainText} text="GLP-1 meds are popular because they work!" />
      <span style={{ fontSize: fontSizes.description, lineHeight: `${1.2 * parseFloat(fontSizes.description)}px` }} className="md:text-start md:text-3xl xs:mt-4 md:mt-12 text-[#0B1838]">
        In clinical trials, GLP-1 users{" "}
        <b>
          lost an average of <span className="bg-gradient-to-b from-[#F8E231] to-[#F06735] bg-clip-text text-transparent">15%</span> of their body weight
        </b>{" "}
        - with{" "}
        <b className=" text-[#0B1838]">
          1 in 3 users losing over <span className="text-[#F06735]">20%*</span>
        </b>
      </span>
      <div className="w-full xs:mt-6 md:mt-0">
      <SectionActionButton text="Next" buttonnavurl="/info" fontSize={fontSizes.button}  />
      </div>
    </div>
  );
};

const SectionRight = ({ availableHeight, availableWidth }: any) => {
  return (
    <div style={{ height: availableWidth > 601 && availableWidth < 1025 ? "auto" : availableWidth < 600 ? "100%" : availableHeight }} className="flex lg:flex-1 flex-col sm:items-centerlg:justify-center lg:px-16 ">
      <div className="flex flex-col">
        <GraphVideo />
        <span className="xs:text-[0.6rem] text-gray-600">Graph for illustrative purposes only to demonstrate potential appetite suppression trends. Individual results will vary.</span>
        <div className="w-full">
          {/* Sections */}
          <div className="flex flex-col sm:flex-row md:flex-1 xs:gap-2 md:gap-4 lg:flex-1">
            {/* Active Section: With GLP-1s */}
            <div className="flex flex-1 w-full justify-center items-center relative sm:w-auto px-4 py-2 mb-2 sm:mb-0 text-center cursor-default">
              <span className="text-[#0B1838] font-bold rounded p-2 xs:text-center xs:text-md sm:text-xs lg:text-xs xl:text-md">With GLP-1s</span>
              <div className="w-full absolute bottom-0 left-0 h-1 bg-gradient-to-r from-[#F06735] to-[#F8E231]"></div>
            </div>

            {/* Inactive Section: Diet without medication */}
            <div className="flex flex-1 w-full justify-center items-center relative sm:w-auto px-4 py-2 mb-2 sm:mb-0 text-center cursor-default">
              <span className="text-[#0B1838] font-bold rounded p-2 xs:text-center xs:text-md sm:text-xs lg:text-xs xl:text-md">Diet without medication</span>
              <div className="w-full absolute bottom-0 left-0 h-1 bg-black"></div>
            </div>
          </div>

          {/* Content Sections */}
          <div className="border border-gray-300 mt-3 lg:text-[0.7rem] text-justify">
            {/* Active Section Content: With GLP-1s */}
            <div className="mb-4">
              <div className="xs:text-[0.4rem] sm:text-[0.6rem] text-gray-600">
                * Based on the average weight loss in three 68-week clinical trials of patients without diabetes who reached and maintained a dose of 2.4 mg/week of GLP-1 treatment, along with a
                reduced-calorie diet and increased physical activity. See details. Results may vary based on starting weight and program adherence. Medication prescriptions are at the discretion of
                medical providers and may not be suitable for everyone. Consult a healthcare professional before starting any weight loss program. Safety info: GLP-1 medications are used to treat
                obesity or overweight individuals (with weight-related problems), along with diet and exercise. They may have serious side effects, including possible thyroid tumors. Do not use if you
                or your family have a history of a type of thyroid cancer called MTC or MEN 2. + Wegovy® is FDA-approved for weight loss. Ozempic® is FDA-approved for type 2 diabetes treatment but may
                be prescribed for weight loss. The trademarks, service marks, trade names (Wegovy®, Ozempic®), and products displayed on this Internet site are protected and belong to their respective
                owners.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function GPL({ availableHeight, marginTop, availableWidth }: any) {
  return (
    <div className="md:overflow-hidden overflow-auto" style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
      <SectionSpliter
        availableWidth={availableWidth}
        availableHeight={availableHeight}
        marginTop={marginTop}
        marginBottom="xs:!mb-0"
        className="sm:justify-center lg:justify-end sm:px-10 lg:px-10"
        sectionLeft={<SectionLeft availableHeight={availableHeight} availableWidth={availableWidth} />}
        sectionRight={<SectionRight availableHeight={availableHeight} availableWidth={availableWidth} />}
      />
    </div>
  );
}
