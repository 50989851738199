import { useDispatch } from "react-redux";
import "./App.css";
import { AppRoutes } from "./AppRoutes";
import Footer from "./components/macrocomponents/Footer";
import Header from "./components/macrocomponents/Header";
import Progress from "./components/microcomponents/Progress";
import { useEffect, useCallback } from "react";
import { setFormReset } from "./lib/store/actions/formAction";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";
import useAvailableDimension from "./hooks/UseAvailableDimension";
import { NavigationProvider } from "../src/components/macrocomponents/NavigationContext"; 

const SESSION_KEY = "sessionData";
const EXPIRATION_TIME = 2500 * 60 * 1000;

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startNewSession = useCallback(() => {
    const sessionStartTime = new Date().getTime();
    localStorage.setItem(SESSION_KEY, JSON.stringify({ startTime: sessionStartTime }));
  }, []);

  const clearSession = useCallback(() => {
    localStorage.removeItem(SESSION_KEY);
    dispatch(setFormReset());
    navigate("/", { replace: true });
    startNewSession();
  }, [dispatch, navigate, startNewSession]);

  const checkSessionExpiration = useCallback(() => {
    const sessionData = JSON.parse(localStorage.getItem(SESSION_KEY) || "{}");
    const currentTime = new Date().getTime();

    if (sessionData && sessionData.startTime) {
      const timeElapsed = currentTime - sessionData.startTime;
      if (timeElapsed >= EXPIRATION_TIME) {
        clearSession();
      } else {
        setTimeout(clearSession, EXPIRATION_TIME - timeElapsed);
      }
    } else {
      startNewSession();
    }
  }, [clearSession, startNewSession]);

  useEffect(() => {
    checkSessionExpiration();
  }, [checkSessionExpiration]);

  const { availableHeight, availableWidth, fixedHeight, headerRef, footerRef, progressBarRef } = useAvailableDimension();

  return (
    <NavigationProvider>
      <ScrollToTop />
      <Header ref={headerRef} />
      <div className="flex flex-1 flex-col scroll-smooth" style={{ height: availableWidth < 600 ? "100%" : availableHeight, marginTop: fixedHeight, minHeight: availableHeight }}>
        <Progress ref={progressBarRef} />
        <AppRoutes availableHeight={availableHeight} marginTop={fixedHeight} availableWidth={availableWidth} />
      </div>
      <Footer ref={footerRef} />
    </NavigationProvider>
  );
}

export default App;
