import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import GPL from "./pages/GPL";
import StateOfResidence from "./pages/StateOfResidence";
import Info from "./pages/Info";
import Greetings from "./pages/Greetings";
import BMICalculator from "./pages/BMICalculator";
import AntiObesityMedicationQuestion from "./pages/AntiObesityMedicationQuestion";
import Motivation from "./pages/Motivation";
import Change from "./pages/Change";
import GreatNews from "./pages/GreatNews";
import HealthHistory from "./pages/HealthHistory";
import ComorbiditiesForm from "./pages/ComorbiditiesForm";
// import CurrentMedications from "./pages/CurrentMedications";
import DisQualified from "./pages/DisQualified";
import Qualified from "./pages/Qualified";

export const RoutePath = {
  home: "/",
  gPL: "/glp",
  info: "/info",
  greetings: "/greetings",
  motivation: "/motivation",
  change: "/change",
  stateOfResidence: "/stateofresidence",
  greatNews: "/greatnews",
  bMICalculator: "/bmi",
  healthHistory: "/healthhistory",
  comorbiditiesForm: "/comorbiditiesform",
  antiObesityMedicationQuestion: "/antiobesity",
  // currentMedications: "/currentmedications",
  qualified: "/qualified",
  disqualifyed: "/disqualified",
};

export const AppRoutes = ({ availableHeight, marginTop, availableWidth }: any) => {
  return (
    <Routes>
      <Route path="/" element={<Home availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/glp" element={<GPL availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/info" element={<Info availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/greetings" element={<Greetings availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/motivation" element={<Motivation availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/change" element={<Change availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/stateofresidence" element={<StateOfResidence availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/greatnews" element={<GreatNews availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/bmi" element={<BMICalculator availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/healthhistory" element={<HealthHistory availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/comorbiditiesform" element={<ComorbiditiesForm availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/antiobesity" element={<AntiObesityMedicationQuestion availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      {/* <Route path="/currentmedications" element={<CurrentMedications availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} /> */}
      <Route path="/disqualified" element={<DisQualified availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/qualified" element={<Qualified availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
    </Routes>
  );
};
