import { SET_FORM_FIELD, SET_FORM_RESET } from "./actionTypes";

export const setFormValue = (data: { field: string; value: string | number | boolean | null | string[] }) => ({
  type: SET_FORM_FIELD,
  payload: data,
});

export interface SetFormFieldAction {
  type: typeof SET_FORM_FIELD;
  payload: {
    field: string;
    value: string | number | boolean | string[] | null;
  };
}
export const setFormReset = () => ({
  type: SET_FORM_RESET,
});
